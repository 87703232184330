
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import useEmitter from "@/core/plugins/emitter.js";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const isUserAuthenticated = store.getters.isUserAuthenticated;
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      emitter,
      isAuthenticated: isUserAuthenticated,
      translate
    };
  },
  methods: {
    openObjectiveModal() {
      this.emitter.emit("open-objective-modal");
    },
    createOrganization() {
      this.emitter.emit("open-organization-modal");
    },
    goPlanificationGuide() {
      this.$router.push("/planification-guide");
    },
    goBack() {
      this.$router.go(-1);
    }
  }
});
